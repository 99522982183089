import {
    GOVUKInitAll,
    HMRCInitAll,
    ClearCookies,
} from 'dvla-external-frontend';

// ensure the stylesheets are included in the compiled pack
require.context('../stylesheets/', false, /^\.\/[^_].*\.(css|scss|sass)$/i);
// ensure images are included in the compiled pack
require.context('../images', true);
// ensure dvla-external-frontend/dist/media are included in the compiled pack
require.context('dvla-external-frontend/dist/media', true);

require("jquery");
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// initialise javascript
GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();